<template>
	<!-- 抽盒商品 -->
	<div class="rootDiv">
		<div class="retrieve">
			<div class="searchDiv flex flex-jb">
				<div>
					<el-input @keydown.enter.native="submitSearch" @clear="submitSearch" clearable class="w-450"
						placeholder="请输入" v-model="retrieveForm.searchValue">
						<el-button slot="append" type="primary" icon="el-icon-search" @click="submitSearch"></el-button>
					</el-input>
				</div>
				<div class="flex flex-ac">
					<div class="fs-16 fc-6b9eff pointer" @click="addList">
						<i class="el-icon-circle-plus-outline mr-10 fs-18"></i>新增
					</div>
				</div>
			</div>
		</div>
		<TableHeightFull class="tableDiv pall-30">
			  <!-- 表格 -->
<!-- 			  <EleTable ref="tableRef" slot="table" :tableData="tableData" :columns="columns" height="100%" :border="false"
			    @row-click="headerRowClick" v-loading="tabLoading" :row-style="{ cursor: 'pointer' }">
			    <template v-slot:taskImg="scope">
			      <img :src="scope.row.taskImg" alt="" class="smallImg" v-if="scope.row.taskImg">
			    </template>
			  </EleTable> -->
			  <!-- 分页 -->
			  <!-- <PagingComponent slot="after" :page-info="pageInfo" @getTableData="getTableData" /> -->
			<!-- 表格 -->
			<EleTable ref="tableRef" slot="table" :tableData="tableData" :columns="columns" height="100%"
				:border="false" v-loading="tabLoading" :row-style="{ cursor: 'pointer' }">
				<template v-slot:taskImg="scope">
				  <img :src="scope.row.taskImg" alt="" class="smallImg" v-if="scope.row.taskImg">
				</template>
			</EleTable>
			<!-- 分页 -->
			<PagingComponent slot="after" :page-info="pageInfo" @getTableData="getTableData" />
		</TableHeightFull>
		<!-- 道具 -->
		<el-dialog :title="dialogTitle" :append-to-body="true" :visible.sync="dialogFormVisible" width="30%"
			@close="resetForm">
			<el-form :model="ruleForm" ref="ruleForm" label-width="100px">
				<el-form-item label="任务名称" prop="taskName" v-if="'taskName' in ruleForm"
					:rules="{required: true, message: '任务名称不能为空', trigger: 'blur'}">
					<el-input type="string" v-model="ruleForm.taskName"></el-input>
				</el-form-item>
				<el-form-item label="任务类型" prop="taskType" v-if="'taskType' in ruleForm"
					:rules="{required: true, message: '请选择一个任务类型', trigger: 'change'}">
					<el-select v-model="ruleForm.taskType" placeholder="请选择">
						<el-option v-for="item in taskTypes" :key="item.value" :label="item.key" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="任务图片" prop="taskImg" v-if="'taskImg' in ruleForm"
					:rules="{required: true, message: '图片不能为空', trigger: 'blur'}">
					<upload-file upStyle="width: 50px; height: 50px; border-radius: 5px; overflow: hidden;"
						iconName="el-icon-upload" iconSize="16" v-model="ruleForm.taskImg"></upload-file>
				</el-form-item>
				<el-form-item label="每次次数" prop="dayNum"
					:rules="{required: true, message: '请输入每次次数', trigger: 'blur'}">
					<el-input type="number" v-model="ruleForm.dayNum"></el-input>
				</el-form-item>
				<el-form-item label="成长值" prop="energy" v-if="'energy' in ruleForm"
					:rules="{required: true, message: '成长值不能为空', trigger: 'blur'}">
					<el-input type="number" v-model="ruleForm.energy"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisible = false">取 消</el-button>
				<el-button type="primary" @click="affirmPopup" :loading="butLoading">{{dialogTitle == '新增'?'确 定':'保 存'}}
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import indexMixin from "@/utils/indexMixin"
	import {
		mapState
	} from 'vuex'
	export default {
		name: 'userGrade',
		mixins: [indexMixin],
		components: {
			"EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
			"TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
			"PagingComponent": (resolve) => require(["@/components/public/PagingComponent"], resolve),
			"UploadFile": (resolve) => require(["@/components/UploadFile"], resolve),
		},
		computed: {
			...mapState('menu', {
				// task_type
				'taskTypes': state => state.dictList ? state.dictList.task_type : [], //任务类型
			})
		},
		data() {
			return {
				retrieveForm: {
					searchValue: '',
				},
				butLoading: false,
				tabLoading: false,
				columns: [{
						type: 'index',
						label: '序号',
						width: '60',
						align: 'center',
						fixed: 'left'
					}, 
					{
						label: '任务名称',
						prop: 'taskName',
						align: 'left',
					}, {
						label: '任务类型',
						prop: 'taskType',
						align: 'left',
					}, 
					{
						columnType: 'custom',
						label: '任务图片',
						prop: 'taskImg',
						align: 'left',
					}, {
						label: '每次次数',
						prop: 'dayNum',
						align: 'left'
					}, {
						label: '成长值',
						prop: 'energy',
						align: 'left',
					},
					// { label: '道具名称', prop: 'propName', align: 'left', 'show-overflow-tooltip': true },
					// {
					//   columnType: 'fun', label: '道具类型', prop: 'propType', align: 'left', width: '120', formatData: (e) => {
					//     let ele = this.propTypes.find(ele => ele.value == e)
					//     return ele ? ele.key : e
					//   }
					// },
					// { label: '有效天数', prop: 'validDay', align: 'left', width: '150', 'show-overflow-tooltip': true },
					// { label: '面额', prop: 'money', align: 'left', width: '100', 'show-overflow-tooltip': true },
					// { label: '门槛', prop: 'doorSill', align: 'left', width: '100', 'show-overflow-tooltip': true },
					// {
					//   columnType: 'fun', label: '优惠劵类型', prop: 'couponsType', align: 'left', width: '120', 'show-overflow-tooltip': true, formatData: (e) => {
					//     let ele = this.couponsTypes.find(ele => ele.value == e)
					//     return ele ? ele.key : e
					//   }
					// },
					{
						columnType: 'button',
						label: '操作',
						align: 'right',
						fixed: 'right',
						buttons: [{
							type: 'text',
							text: '编辑',
							fn: this.editList,
						}, {
							type: 'text',
							text: '删除',
							fn: this.deleteList,
						}],
					},
				],
				tableData: [],
				//弹框
				dialogTitle: '新增',
				dialogFormVisible: false,
				ruleForm: {
					dayNum: '', //累计消费
					energy: '', //月消费
					taskName: '', //等级
					taskType: '', //道具名称
				},
			}
		},
		mounted() {
			this.getTableData()
		},
		methods: {
			//查询
			submitSearch() {
				this.pageInfo.pageNum = 1
				this.getTableData()
			},
			//合并行或列
			arraySpanMethod({
				row,
				column,
				rowIndex,
				columnIndex
			}) {
				if (columnIndex === 1 || columnIndex === 2) {
					var spanArr = this.getSpanArr(this.tableData, 'level')
					const _row = spanArr[rowIndex]
					const _col = _row > 0 ? 1 : 0
					return {
						rowspan: _row,
						colspan: _col
					}
				}
			},
			// 处理合并行的数据
			getSpanArr(data, spanKey) {
				var spanArr = []
				var pos = ''
				for (var i = 0; i < data.length; i++) {
					if (i === 0) {
						spanArr.push(1)
						pos = 0
					} else {
						// 判断当前元素与上一个元素是否相同
						if (data[i][spanKey] === data[i - 1][spanKey]) {
							spanArr[pos] += 1
							spanArr.push(0)
						} else {
							spanArr.push(1)
							pos = i
						}
					}
				}
				return spanArr
			},
			//导出
			exportExcel() {},
			//获取表格数据
			getTableData() {
				this.tabLoading = true
				this.$http.get('/userLevelTask/findUserLevelTaskList', {
					params: {
						currentPage: this.pageInfo.pageNum,
						pageSize: this.pageInfo.pageSize,
					}
				}).then(({
					data: result
				}) => {
					this.tabLoading = false
					this.tableData = result.data.list
					this.pageInfo.total = result.data.total
				})
			},
			//新增列表
			addList() {
				this.dialogTitle = '新增'
				this.ruleForm = {
					dayNum: '', //累计消费
					energy: '', //月消费
					taskImg:'',//活动图片
					taskName: '', //等级
					taskType: '', //道具名称
				}
				this.dialogFormVisible = true
			},
			//编辑列表
			editList(row) {
				this.dialogTitle = '编辑'
				this.dialogFormVisible = true
				this.ruleForm = JSON.parse(JSON.stringify(row))
				// this.$refs['grade-Detail'].init(row.id)
			},
			//保存道具
			affirmPopup() {
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						this.butLoading = true
						let apiUrl = ''
						if (this.dialogTitle == '新增') {
							apiUrl = '/userLevelTask/addUserLevelTask'
						} else if (this.dialogTitle == '编辑') {
							apiUrl = '/userLevelTask/editUserLevelTask'
						}
						this.$http.post(apiUrl, this.ruleForm).then((result) => {
							this.dialogFormVisible = false
							this.getTableData()
							this.$message({
								message: his.dialogTitle + '成功',
								type: 'success'
							})
							this.butLoading = false
						}).catch(err => {
							this.butLoading = false
						})
					}
				})
			},
			//删除
			deleteList(row) {
				this.$confirm('您确认删除？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(_ => {
					this.$http.post('/userLevelTask/removeUserLevelTaskById', {
						id: row.id
					}).then(({
						data: result
					}) => {
						this.getTableData()
						this.$message({
							message: '删除成功',
							type: 'success'
						})
					})
				}).catch(_ => {})
			},
			//重置清除校验
			resetForm() {
				Object.assign(this.ruleForm, this.$options.data().ruleForm)
				this.$nextTick(() => {
					this.$refs.ruleForm.clearValidate()
				})
			},
		},
	}
</script>
<style lang="less" scoped>
</style>
